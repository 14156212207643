<template>
  <div>
    <b-modal
      centered
      :title="$t('tooltips.preview')"
      ok-only
      v-model="showPreview"
      size="xl"
      hide-footer
    >
    <HeaderType :header="header"></HeaderType>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, VBModal, BAlert} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import HeaderType from '../header-store/header-type.vue'
export default {
  components: {
    BButton,
    BModal,
    BAlert,
    HeaderType
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data: () => ({
    showPreview: false,
    header: {},
  }),
    methods: {
        show(item){
            this.showPreview = true
            this.header = item
        }
    },
}
</script>