<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{$t('labels.show')}}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{$t('labels.entries')}}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <div>
                <b-button
                  variant="primary"
                  :to="$router.resolve({ name: 'apps-headers-sotre' }).href"
                >
                  <span class="text-nowrap">{{$t('buttons.add')}}</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-overlay :show="loading"
                 :variant="$store.state.appConfig.layout.skin"
                 blur="2"
                 class="p-50">
        <b-table
            ref="refHeaderListTable"
            class="position-relative"
            :items="fetchHeaders"
            responsive
            :fields="tableColumns"
            primary-key="_id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            style="min-height: 60vh"
        >
          <template #cell(logo)="data">
            <image-table-field :url="data.item.logo.href"></image-table-field>
          </template>
          <template #cell(background)="data">
            <color-table-field :color="data.item.background_color">
              <image-table-field :url="data.item.background_image"></image-table-field>
            </color-table-field>
          </template>
          <template #cell(auth)="data">
            <color-table-field :color="data.item.auth.background_color">
              <image-table-field :url="data.item.auth.background_image"></image-table-field>
            </color-table-field>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <!-- <b-button variant="primary" class="btn-icon rounded-circle mr-1"
              v-b-tooltip.hover.top="$t('tooltips.detail')"
              :to="{ name: 'apps-users-view', params: { id: data.item._id } }" size="sm">
              <feather-icon icon="FileTextIcon" />
            </b-button> -->

            <b-button variant="primary" class="btn-icon rounded-circle mr-1"
                      v-b-tooltip.hover.top="$t('tooltips.preview')" @click="preview(data.item)" size="sm">
              <feather-icon icon="EyeIcon"/>
            </b-button>

            <b-button variant="primary" class="btn-icon rounded-circle mr-1"
                      v-b-tooltip.hover.top="$t('tooltips.edit')" size="sm"
                      :to="{ name: 'apps-headers-sotre', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button variant="primary" class="btn-icon rounded-circle mr-1"
                      v-b-tooltip.hover.top="$t('tooltips.delete')" @click="removeHeader(data.item._id)" size="sm">
              <feather-icon icon="TrashIcon"/>
            </b-button>
          </template>
        </b-table>

      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{$t('labels.showing')}} {{ dataMeta.from }} {{$t('labels.to')}} {{ dataMeta.to }} {{$t('labels.of')}} {{ dataMeta.of }} {{$t('labels.entries')}}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalHeaders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <previewModal ref="previewModal" />
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {onUnmounted, ref} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useHeadersList from './useHeadersList'
import headerStoreModule from '../headerStoreModule'
import ImageTableField from '@/views/components/ImageTableField'
import ColorTableField from '@/views/components/ColorTableField'
import previewModal from './previewModal.vue'


export default {
  components: {
    ImageTableField,
    ColorTableField,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBTooltip,
    previewModal,

    vSelect,
  },
  data(){
    return{

    }
  },

  computed: {
      tableColumns() {
        return [
          {key: 'name', sortable: false, label: this.$t('labels.name')},
          {key: 'logo', sortable: false, label: this.$t('labels.logo')},
          {key: 'background', sortable: false, label: this.$t('labels.background')},
          //{ key: 'auth', sortable: false },
          //{ key: 'seeker', sortable: false, label: this.$t('labels.seeker') },
          {key: 'actions', label: this.$t('labels.actions')},
        ]
      }
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  setup() {
    const HEADER_APP_STORE_MODULE_NAME = 'app-header'

    if (!store.hasModule(HEADER_APP_STORE_MODULE_NAME)) store.registerModule(HEADER_APP_STORE_MODULE_NAME, headerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(HEADER_APP_STORE_MODULE_NAME)) store.unregisterModule(HEADER_APP_STORE_MODULE_NAME)
    })

    const isAddNewHeaderSidebarActive = ref(false)

    const {
      fetchHeaders,
      perPage,
      currentPage,
      totalHeaders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refHeaderListTable,
      refetchData,
      recordDeleted,
      loading,
      // UI
           
      resolveHeaderStatusVariant,
    } = useHeadersList()

    return {
      // Sidebar
      isAddNewHeaderSidebarActive,
      fetchHeaders,
      perPage,
      currentPage,
      totalHeaders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refHeaderListTable,
      refetchData,
      recordDeleted,
      loading,
      // UI
      resolveHeaderStatusVariant,

    }
  },
  methods: {
    removeHeader(Id) {
      store.dispatch('app-header/removeHeader', { id: Id })
        .then(() => {
          this.recordDeleted = !this.recordDeleted
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Header removed',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },
    preview(item) {
      this.$refs.previewModal.show(item)      
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
